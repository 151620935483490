<template>
  <div class="home">
    <div class="news-HImg"><img src="@/assets/images/1551929445.jpg" /></div>
    <div class="news-minimenu">
      <div class="container">
        <div class="form-group">
          <div class="work-box" v-for="(info, index) in jobList" :key="index">
            <p>
              <a
                class="search-res-title"
                :href="SearchResUrl + 'solutiondetails?id=' + info.soluId"
                >{{ info.comJobTitle }}</a
              >
            </p>
            <p class="work-metas-box">
              <span>{{ info.createTime | filtersData }}</span>
              <span>
                <b-icon icon="geo-alt-fill" width="12" height="12"></b-icon>
                &nbsp;&nbsp;{{ info.comJobWorkSite }}
              </span>
              <span>
                <b-icon icon="person-fill" width="12" height="12"></b-icon>
                &nbsp;&nbsp;{{ info.comJobNumbers }}
              </span>
              <span>
                <b-icon icon="credit-card-fill" width="12" height="12"></b-icon>
                &nbsp;&nbsp;{{ info.comJobSalary }}
              </span>
            </p>
            <hr />
            <div>
              <section class="met-editor clearfix">
                <div v-html="info.comJobContent"></div>
              </section>
            </div>
            <hr />
            <p>
              <a
                class="online-submit-btn"
                href="javascript:;"
                data-toggle="modal"
                data-target="#onlineSubmitJob"
                @click="setComJobId(info.comJobId)"
                >在线应聘</a
              >
            </p>
          </div>
        </div>
        <div class="form-group">&nbsp;</div>
      </div>
    </div>
    <!-- 模态框 -->
    <div class="modal fade" id="onlineSubmitJob">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- 模态框头部 -->
          <div class="modal-header">
            <h4 class="modal-title">在线应聘</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- 模态框主体 -->
          <div class="modal-body">
            <form action="">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="姓名"
                  id="username"
                  v-model="form.resumeName"
                />
                <p class="help-block">{{ invalid.resumeName }}</p>
              </div>
              <div class="form-group">&nbsp;</div>
              <div class="form-group">
                <label for="gender">性别</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderMan"
                    checked
                    value="0"
                    v-model="form.resumeGender"
                  />
                  <label class="form-check-label" for="genderMan">男</label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderWoman"
                    value="1"
                    v-model="form.resumeGender"
                  />
                  <label class="form-check-label" for="genderWoman">女</label>
                </div>
              </div>
              <div class="form-group">
                <label for="mobile"></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="电话"
                  id="mobile"
                  v-model="form.resumePhone"
                />
                <p class="help-block">{{ invalid.resumePhone }}</p>
              </div>
              <div class="form-group">
                <label for="email"></label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="邮箱"
                  id="email"
                  v-model="form.resumeEmail"
                />
                <p class="help-block">{{ invalid.resumeEmail }}</p>
              </div>
              <!-- <div class="form-group">
                <label for="favorite"></label>
                <textarea
                  class="form-control"
                  id="favorite"
                  rows="4"
                  placeholder="业余爱好"
                  v-model="form.resumeFavorite"
                ></textarea>
              </div> -->
              <p>&nbsp;</p>
              <div class="upload-box">
                <div class="input-group upload-input-box-faker">
                  <label for="resume"></label>
                  <div class="input-group-prepend">
                    <div class="input-group-text" id="resume">
                      <b-icon icon="upload" width="15" height="15"></b-icon>
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="上传简历"
                    v-model="fileName"
                  />
                </div>
                <div class="input-group upload-input-box">
                  <input
                    type="file"
                    class="form-control"
                    id="resume"
                    name="resume"
                    placeholder="上传简历"
                    multiple=""
                    ref="fileInt"
                    @change="changeHandle"
                  />
                </div>
              </div>
              <div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="验证码"
                    v-model="form.valiCode"
                  />
                  <span class="input-group-addon captcha-box"
                    ><img
                      class="captcha-img"
                      @click="getCaptcha()"
                      :src="captcha"
                  /></span>
                </div>
                <p class="help-block">{{ invalid.valiCode }}</p>
              </div>

              <div class="submit-resume-space">&nbsp;</div>
              <p>
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="submitResume()"
                >
                  提交
                </button>
                &nbsp;
                <button
                  class="btn btn-cancel"
                  id="closeModelBtn"
                  data-dismiss="modal"
                >
                  取消
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionDetails",
  data() {
    return {
      keyword: "",
      //解决方案信息
      jobList: [],
      show: false,
      count: 5,
      timer: null,
      fileName: "",
      captcha: "",
      repeatSubmitFlag: true,
      form: {
        resumeJobId: 0,
        resumeGender: 0,
        resumeFavorite: "",
        resumeFile: "",
      },
      invalid: {
        resumeName: "",
        resumePhone: "",
        resumeEmail: "",
        valiCode: "",
      },
    };
  },
  mounted() {
    var _this = this;
    _this.keyword = this.$route.query.keyword; //获取解决方案ID
    //获取解决方案信息
    _this.getJobList();
  },
  methods: {
    //搜索解决方案
    getJobList() {
      var _this = this;
      _this.$axios
        .get("system/job/websiteList?pageNum=1&pageSize=10")
        .then(function (response) {
          if (response.data.code == 200) {
            if (response.data.rows) {
              _this.jobList = response.data.rows;
            } else {
              console.info(response.data.rows);
            }
          }
        });
    },
    //验证码
    getCaptcha() {
      var _this = this;
      _this.$axios.get("captchaImage").then(function (response) {
        if (response.data.code == 200) {
          if (response.data) {
            _this.captcha = "data:image/gif;base64," + response.data.img;
          }
        }
      });
    },
    //上传简历
    changeHandle() {
      var _this = this;
      const file = _this.$refs.fileInt.files[0];
      const data = new FormData();
      data.append("file", file);
      _this.$axios
        .post("system/job/upload/resume", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          _this.fileName = file.name;
          _this.form.resumeFile = res.data.fileName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //设置公司工作ID
    setComJobId(comJobId) {
      var _this = this;
      _this.form.resumeJobId = comJobId;
      _this.getCaptcha();
    },
    //提交简历
    submitResume() {
      var _this = this;
      var checkRes = _this.checkResume();
      if (!checkRes) {
        return false;
      }
      if (_this.repeatSubmitFlag) {
        _this.repeatSubmitFlag = false;
        _this.$axios
          .post("system/resume/websiteAdd", _this.form)
          .then(function (response) {
            _this.repeatSubmitFlag = true;
            if (response.data.code == 200) {
              alert("提交成功");
              _this.form.resumeJobId = 0;
              _this.form.resumeGender = 0;
              _this.form.resumeFavorite = "";
              _this.form.resumeFile = "";
              _this.form.resumeName = "";
              _this.form.resumePhone = "";
              _this.form.resumeEmail = "";
              _this.form.valiCode = "";
              document.getElementById("closeModelBtn").click();
            }
            return false;
          });
      }
    },
    //检查简历
    checkResume() {
      var _this = this;
      var flag = true;
      _this.invalid.resumeName = "";
      _this.invalid.resumePhone = "";
      _this.invalid.resumeEmail = "";
      _this.invalid.valiCode = "";
      if (!_this.isEmpty(_this.form.resumeName)) {
        _this.invalid.resumeName = "不能为空";
        flag = false;
      }
      if (!_this.isEmpty(_this.form.resumePhone)) {
        _this.invalid.resumePhone = "不能为空";
        flag = false;
      }
      if (!_this.isEmpty(_this.form.resumeEmail)) {
        _this.invalid.resumeEmail = "不能为空";
        flag = false;
      }
      if (!_this.isEmpty(_this.form.valiCode)) {
        _this.invalid.valiCode = "不能为空";
        flag = false;
      }
      return flag;
    },
    //判断字符是否为空的方法
    isEmpty(obj) {
      if (typeof obj == "undefined" || obj == null || obj == "") {
        return false;
      } else {
        return true;
      }
    },
  },
  filters: {
    filtersNewsTitle(val) {
      if (val != null && val != "") {
        return val.substr(0, 12) + "......";
      } else {
        return "";
      }
    },
    //提纯富文本中的文字并显示前150的字符
    filtersText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1, "");
        return msg.substr(0, 150);
      } else {
        return "";
      }
    },
    filtersData(val) {
      if (val != null && val != "") {
        return val.substr(0, 10);
      } else {
        return "";
      }
    },
  },
  watch: {
    //监听路由改变
    $route(to) {
      var _this = this;
      //获取解决方案类型
      _this.soluId = to.query.id;
      //获取解决方案信息
      _this.getJobList();
    },
  },
};
</script>

<style scoped>
.help-block {
  color: red;
}
.news-HImg img {
  width: 100%;
  height: 200px;
}

.captcha-box {
  display: flex;
}

.captcha-img {
  margin: auto;
  height: 2.2rem;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0rem;
}

input[type="radio"] + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.1rem solid #00c9a1;
  margin-right: 0.213333rem;
  box-sizing: border-box;
}

input[type="radio"]:checked + label::before {
  border: 0.38rem solid #00c9a1;
  background-clip: content-box;
  box-sizing: border-box;
}

input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.upload-box {
  position: relative;
  height: 3.5rem;
}

.upload-input-box-faker {
  position: absolute;
}

.upload-input-box {
  position: absolute;
  opacity: 0;
}

.submit-resume-space {
  height: 5rem;
}

.home {
  background-color: #f2f2f2;
  color: #2a333c;
}

.news-header {
  height: 120px;
  background: #00c9a1;
  text-align: center;
}

.news-header h2 {
  line-height: 120px;
  color: #37474f;
}

.news-minimenu {
  width: 100%;
  background: #ffffff;
}

.news-minimenu svg {
  margin-top: -5px;
}

.work-box {
  position: relative;
  padding: 3rem 0 1rem 0;
  border-bottom: #f2f2f2 1px solid;
}

.work-box .work-box-btn {
  position: absolute;
  top: 3.4rem;
  right: 0.5rem;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.work-box .form-control {
  border-radius: 2rem;
  background: #f3f7f9;
  border-color: #e4eaec;
  color: #76838f;
  font-size: 14px;
}

.work-box .form-control:focus {
  border: 1px solid #00c9a1;
  box-shadow: none;
}

a {
  text-decoration: none !important;
}

.search-res-title {
  font-size: 1.5rem;
  color: #37474f;
  text-decoration: none;
}

.search-res-title:hover {
  color: #00c9a1;
}

.search-res-link {
  font-size: 1rem;
  color: #89bceb;
}

.search-res hr {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 0rem;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.met-editor {
  padding: 0 30px 30px 30px;
  font-size: 16px;
  line-height: 2.8;
}

.met-editor p {
  margin-bottom: 0px;
}

.work-metas-box {
  color: #a3afb7;
  font-size: 12px;
  padding-top: 0.9rem;
}

.work-metas-box span {
  margin-right: 10px !important;
}

.btn-primary {
  border-color: #00c9a1;
  background-color: #00c9a1;
  border-radius: 1px;
  font-size: 0.95rem;
}

.btn-primary:focus,
.btn-primary:hover {
  border-color: #00c9a1;
  background-color: #00c9a1;
  border-radius: 1px;
  font-size: 0.95rem;
}

.btn-cancel {
  color: #76838f;
  border-color: #e4eaec;
  background-color: #e4eaec;
  border-radius: 1px;
  font-size: 0.95rem;
}

.btn-cancel:focus,
.btn-cancel:hover {
  border-color: #f3f7f9;
  background-color: #f3f7f9;
}

.input-group-text {
  height: 2.2rem;
  background: #00c9a1;
  color: #ffffff;
}

.modal-dialog {
  max-width: 600px;
}

form input:focus {
  border: solid 1px #00c9a1;
}

.modal-header {
  background: #00c9a1;
}

.modal-title {
  color: #ffffff;
  font-size: 1.1rem;
}

.close {
  color: #ffffff;
  font-size: 1.2rem;
}

.close:hover {
  color: #ffffff;
}

.online-submit-btn {
  display: block;
  width: 85px;
  color: #00c9a1;
  border-radius: 2px;
  border: 1px solid;
  text-align: center;
  line-height: 35px;
}

.online-submit-btn:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #00c9a1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c6d3d7 !important;
  font-size: 14px;
}
</style>
<style>
.form-control {
  font-size: 1rem;
  line-height: 1.571429;
  display: block;
  width: 100%;
  color: #76838f;
  border: 1px solid #e4eaec;
  border-radius: 0.215rem;
  height: 2.2rem;
  border-color: #e4eaec;
}
</style>
